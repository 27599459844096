<template>
  <div class="bt-transaksi pb-5 mb-5">
    <div class="pa-5">
      <div class="d-flex align-center">
        <v-row class="mt-1">
          <v-col cols="12">
            <v-card
              class="pa-5 rounded-lg"
              :style="{
                backgroundColor: $vuetify.theme.dark ? 'var(--dark-bg)' : 'var(--light-bg)',
              }"
            >
              <v-row>
                <v-col cols="12">
                  <div class="d-flex">
                    <div class="rounded-pill purple lighten-1 me-2 px-2 d-flex align-center justify-center py-2 elevation-3">
                      <v-icon dense color="white">mdi-human-greeting-variant</v-icon>
                    </div>
                    <div class="ml-1 d-flex justify-center align-center">
                      <span class="font-weight-bold align-center"> Penagihan </span>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-divider class="my-4" />
              <v-row>
                <v-col cols="12" class="px-1">
                  <div class="backCustomSatu py-5 rounded-lg evaluation-3" style="height: 100%">
                    <router-link to="/penagihan/dashboard">
                      <div class="d-flex justify-center">
                        <v-icon large color="purple darken-3" class="pa-2 rounded-circle" style="background-color: yellow"> mdi-cash-edit </v-icon>
                      </div>
                    </router-link>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div class="d-flex align-center">
        <v-row class="mt-1">
          <v-col cols="12">
            <v-card
              class="pa-5 rounded-lg"
              :style="{
                backgroundColor: $vuetify.theme.dark ? 'var(--dark-bg)' : 'var(--light-bg)',
              }"
            >
              <v-row>
                <v-col cols="12">
                  <div class="d-flex">
                    <div class="rounded-pill purple lighten-1 me-2 px-2 d-flex align-center justify-center py-2 elevation-3">
                      <v-icon dense color="white">mdi-human-greeting-proximity</v-icon>
                    </div>
                    <div class="ml-1 d-flex justify-center align-center">
                      <span class="font-weight-bold align-center"> Penilaian </span>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-divider class="my-4" />
              <v-row>
                <v-col cols="12" class="px-1">
                  <div class="backCustomSatu py-5 rounded-lg evaluation-3" style="height: 100%">
                    <router-link to="/penilaian/dashboard">
                      <div class="d-flex justify-center">
                        <v-icon large color="purple darken-3" class="pa-2 rounded-circle" style="background-color: yellow"> mdi-tooltip-edit-outline </v-icon>
                      </div>
                    </router-link>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div class="d-flex align-center">
        <v-row class="mt-1">
          <v-col cols="12">
            <v-card
              class="pa-5 rounded-lg"
              :style="{
                backgroundColor: $vuetify.theme.dark ? 'var(--dark-bg)' : 'var(--light-bg)',
              }"
            >
              <v-row>
                <v-col cols="12">
                  <div class="d-flex">
                    <div class="rounded-pill purple lighten-1 me-2 px-2 d-flex align-center justify-center py-2 elevation-3">
                      <v-icon dense color="white">mdi-chart-bubble</v-icon>
                    </div>
                    <div class="ml-1 d-flex justify-center align-center">
                      <span class="font-weight-bold align-center"> Laporan </span>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-divider class="my-4" />
              <v-row>
                <v-col cols="12" class="px-1">
                  <div class="backCustomSatu py-5 rounded-lg evaluation-3" style="height: 100%">
                    <router-link to="/laporan">
                      <div class="d-flex justify-center">
                        <v-icon large color="purple darken-3" class="pa-2 rounded-circle" style="background-color: yellow"> mdi-file-chart </v-icon>
                      </div>
                    </router-link>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Lhp",
};
</script>
