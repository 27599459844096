var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bt-transaksi pb-5 mb-5"},[_c('div',{staticClass:"pa-5"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-5 rounded-lg",style:({
              backgroundColor: _vm.$vuetify.theme.dark ? 'var(--dark-bg)' : 'var(--light-bg)',
            })},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"rounded-pill purple lighten-1 me-2 px-2 d-flex align-center justify-center py-2 elevation-3"},[_c('v-icon',{attrs:{"dense":"","color":"white"}},[_vm._v("mdi-human-greeting-variant")])],1),_c('div',{staticClass:"ml-1 d-flex justify-center align-center"},[_c('span',{staticClass:"font-weight-bold align-center"},[_vm._v(" Penagihan ")])])])])],1),_c('v-divider',{staticClass:"my-4"}),_c('v-row',[_c('v-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('div',{staticClass:"backCustomSatu py-5 rounded-lg evaluation-3",staticStyle:{"height":"100%"}},[_c('router-link',{attrs:{"to":"/penagihan/dashboard"}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-icon',{staticClass:"pa-2 rounded-circle",staticStyle:{"background-color":"yellow"},attrs:{"large":"","color":"purple darken-3"}},[_vm._v(" mdi-cash-edit ")])],1)])],1)])],1)],1)],1)],1)],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-5 rounded-lg",style:({
              backgroundColor: _vm.$vuetify.theme.dark ? 'var(--dark-bg)' : 'var(--light-bg)',
            })},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"rounded-pill purple lighten-1 me-2 px-2 d-flex align-center justify-center py-2 elevation-3"},[_c('v-icon',{attrs:{"dense":"","color":"white"}},[_vm._v("mdi-human-greeting-proximity")])],1),_c('div',{staticClass:"ml-1 d-flex justify-center align-center"},[_c('span',{staticClass:"font-weight-bold align-center"},[_vm._v(" Penilaian ")])])])])],1),_c('v-divider',{staticClass:"my-4"}),_c('v-row',[_c('v-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('div',{staticClass:"backCustomSatu py-5 rounded-lg evaluation-3",staticStyle:{"height":"100%"}},[_c('router-link',{attrs:{"to":"/penilaian/dashboard"}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-icon',{staticClass:"pa-2 rounded-circle",staticStyle:{"background-color":"yellow"},attrs:{"large":"","color":"purple darken-3"}},[_vm._v(" mdi-tooltip-edit-outline ")])],1)])],1)])],1)],1)],1)],1)],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-5 rounded-lg",style:({
              backgroundColor: _vm.$vuetify.theme.dark ? 'var(--dark-bg)' : 'var(--light-bg)',
            })},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"rounded-pill purple lighten-1 me-2 px-2 d-flex align-center justify-center py-2 elevation-3"},[_c('v-icon',{attrs:{"dense":"","color":"white"}},[_vm._v("mdi-chart-bubble")])],1),_c('div',{staticClass:"ml-1 d-flex justify-center align-center"},[_c('span',{staticClass:"font-weight-bold align-center"},[_vm._v(" Laporan ")])])])])],1),_c('v-divider',{staticClass:"my-4"}),_c('v-row',[_c('v-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('div',{staticClass:"backCustomSatu py-5 rounded-lg evaluation-3",staticStyle:{"height":"100%"}},[_c('router-link',{attrs:{"to":"/laporan"}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-icon',{staticClass:"pa-2 rounded-circle",staticStyle:{"background-color":"yellow"},attrs:{"large":"","color":"purple darken-3"}},[_vm._v(" mdi-file-chart ")])],1)])],1)])],1)],1)],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }